<template>
    <div class="Profile-test">
        <ul class="nav nav-tabs test-tabs">
            <li class="nav-item">
                <a class="nav-link test-tabs__title" 
                    @click.prevent="setActive('tests')" 
                    :class="{ active: isActive('tests') }" href="#tests">
                    {{$t("profile.testing.test-tab")}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link test-tabs__title" 
                    @click.prevent="setActive('results')" 
                    :class="{ active: isActive('results') }" href="#results">
                    {{$t("profile.testing.result-tab")}}
                </a>
            </li>
        </ul>

        <div class="tab-content test-tab-content">
            <div class="tab-pane fade" :class="{ 'active show': isActive('tests') }" id="tests">
                <div class="test-tab-content__cards">
                    <div class="test-card" v-for="test in tests">
                        <div class="test-card__left">
                            <div class="test-card__title">
                                {{ test.title }}
                            </div>
                            <div class="test-card__time">
                                {{$t("profile.testing.test-time")}}{{ moment(test.created_at).format('LLL') }}
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <img src="/images/profile/globe.svg" class="test-card__lang-icon" alt="">
                                <div class="test-card__language">
                                    {{ $t("profile.testing.test-language") }}{{ $t("profile.testing." + test_lang) }}
                                </div>
                            </div>
                        </div>
                        <div class="test-card__right">
                            <button class="test-card__btn test-card__btn--light-purple" v-if="test.disabled">
                                {{ $t("profile.testing.test-btn-disabled") }}
                            </button>
                            <button class="test-card__btn test-card__btn--purple" @click="goToTest(test.id, test_lang)" v-else>
                                {{ $t("profile.testing.test-btn-start") }}
                            </button>
                        </div>
                    </div>
                    <!--
                    <div class="test-card">
                        <div class="test-card__left">
                            <div class="test-card__title">
                                {{$t("profile.testing.test-physics")}}
                            </div>
                            <div class="test-card__time">
                                {{$t("profile.testing.test-time")}}
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <img src="/images/profile/globe.svg" class="test-card__lang-icon" alt="">
                                <div class="test-card__language">
                                    {{$t("profile.testing.test-language")}}
                                </div>
                            </div>
                        </div>
                        <div class="test-card__right">
                            <button class="test-card__btn">
                                {{$t("profile.testing.test-btn-continue")}}
                            </button>
                            <div class="test-card__remaining-time">
                                {{$t("profile.testing.test-remaining-time")}}
                            </div>
                        </div>
                    </div>
                    <div class="test-card">
                        <div class="test-card__left">
                            <div class="test-card__title">
                                {{$t("profile.testing.test-math")}} 
                            </div>
                            <div class="test-card__time">
                                {{$t("profile.testing.test-time")}}
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <img src="/images/profile/globe.svg" class="test-card__lang-icon" alt="">
                                <div class="test-card__language">
                                    {{$t("profile.testing.test-language")}}
                                </div>
                            </div>
                        </div>
                        <div class="test-card__right">
                            <button class="test-card__btn test-card__btn--purple">
                                {{$t("profile.testing.test-btn-start")}}
                            </button>
                        </div>
                    </div>
                    <div class="test-card">
                        <div class="test-card__left">
                            <div class="test-card__title">
                                {{$t("profile.testing.test-chemistry")}}
                            </div>
                            <div class="test-card__time">
                                {{$t("profile.testing.test-time")}}
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <img src="/images/profile/globe.svg" class="test-card__lang-icon" alt="">
                                <div class="test-card__language">
                                    {{$t("profile.testing.test-language")}}
                                </div>
                            </div>
                        </div>
                        <div class="test-card__right">
                            <button class="test-card__btn test-card__btn--light-purple">
                                {{$t("profile.testing.test-btn-start")}}
                            </button>
                            <div class="test-card__remaining-time">
                                {{$t("profile.testing.test-remaining-time")}}
                            </div>
                        </div>
                    </div>
                    <div class="test-card">
                        <div class="test-card__left">
                            <div class="test-card__title">
                                {{$t("profile.testing.test-chemistry")}}
                            </div>
                            <div class="test-card__time">
                                {{$t("profile.testing.test-time")}}
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <img src="/images/profile/globe.svg" class="test-card__lang-icon" alt="">
                                <div class="test-card__language">
                                    {{$t("profile.testing.test-language")}}
                                </div>
                            </div>
                        </div>
                        <div class="test-card__right">
                            <button class="test-card__btn test-card__btn--light-purple">
                                {{$t("profile.testing.test-btn-completed")}}
                            </button>
                        </div>
                    </div>
                    -->
                </div>
                <b-pagination
                    v-if="testsTotal > testsPerPage"
                    v-model="testsCurrentPage"
                    :total-rows="testsTotal"
                    :per-page="testsPerPage"
                    @change="getTests"
                    last-number
                    first-number
                    hide-goto-end-buttons
                    align="center"
                    next-text=">"
                    prev-text="<"
                ></b-pagination>
            </div>

            <div class="tab-pane fade" :class="{ 'active show': isActive('results') }" id="results">
                <div class="test-tab-content__cards">
                    <div class="test-card" v-for="result in results">
                        <div class="test-card__left">
                            <div class="test-card__title">
                                {{ result.quiz_title }}
                            </div>
                            <div class="test-card__time">
                                {{$t("results.test-passed-time")}} {{ moment(result.date).format('LLL') }}
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <img src="/images/profile/globe.svg" class="test-card__lang-icon" alt="">
                                <div class="test-card__language">
                                    {{$t("profile.testing.test-language")}}{{ $t("profile.testing." + test_lang) }}
                                </div>
                            </div>
                        </div>
                        <div class="test-card__right text-right">
                            <div class="test-card__result">
                                {{ result.correct }}/{{ result.max_result }}
                            </div>
                            <div class="text-center">
                                <button v-if="result.show_recommendation" @click="$router.push({ name: 'result', params: {id: result.user_quiz_id }})" class="test-card__btn test-card__btn--more">
                                    {{$t("profile.testing.more-btn")}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <b-pagination
                    v-if="resultsTotal > resultsPerPage"
                    v-model="resultsCurrentPage"
                    :total-rows="resultsTotal"
                    :per-page="resultsPerPage"
                    @change="getResults"
                    last-number
                    first-number
                    hide-goto-end-buttons
                    align="center"
                    next-text=">"
                    prev-text="<"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            test_lang: 'ru',
            activeItem: 'tests',
            isHiddentests: true,
            isHiddenresults: true,
            tests: [],
            results: [],
            testsCurrentPage: 1,
            testsTotal: 0,
            testsPerPage: 0,
            resultsCurrentPage: 1,
            resultsTotal: 0,
            resultsPerPage: 0,
        }
    },
    mounted() {
        moment.locale('ru')
        if (localStorage.getItem('user') && localStorage.getItem('user') !== '') {
            let user = JSON.parse(localStorage.getItem('user'))
            if (user.education) {
                this.test_lang = user.education.test_lang
                if (user.education.test_lang == null || user.education.test_lang === '') {
                    this.test_lang = 'ru'
                }
            } else {
                this.test_lang = 'ru'
            }
        }
        this.getTests(this.testsCurrentPage)
    },
    methods: {
        getTests(page) {
            this.loading = true
            this.$http.get(`${window.API_ROOT}/api/quiz?page=${page}`)
                .then((res) => {
                    this.tests = res.body.data
                    this.testsTotal = res.body.meta.total
                    this.testsPerPage = res.body.meta.per_page
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        goToTest(id, lang) {
            this.$router.push({ name: 'quiz-enter', params: {id: id, lang: lang} })
        },
        isActive (menuItem) {
            return this.activeItem === menuItem
        },
        setActive (menuItem) {
            this.activeItem = menuItem
            if (menuItem === 'results') {
                this.getResults(this.resultsCurrentPage)
            }
        },
        getResults(page) {
            this.$http.get(`${window.API_ROOT}/api/quiz/results?page=${page}`)
                .then((res) => {
                    this.results = res.body.items
                    this.resultsTotal = res.body.total
                    this.resultsPerPage = res.body.per_page
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        }
    },
}
</script>
<style lang="less" scoped>
.Profile-test {
    max-width: 100%;
}
.test-tabs {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 34px;
}
.nav-tabs.test-tabs {
    border-bottom: none;
}
.test-tabs__title {
    width: 218px;
    text-align: center;
    border: 1px solid #473F95;
    box-sizing: border-box;
    padding: 8px 24px;
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
.test-tabs__title.active {
    background: #473F95;
    border: 1px solid #473F95;
    color: #FFFFFF;
}
.test-tabs__title:hover {
    border: 1px solid #473F95;
}
.test-tabs .nav-item:first-child .test-tabs__title {
    border-radius: 5px 0 0 5px;
} 

.test-tabs .nav-item:last-child .test-tabs__title {
    border-radius: 0 5px 5px 0;
} 

.test-tab-content__cards .test-card {
    margin: 0 auto 32px auto;
}
.test-card {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    max-width: 100%;
}
.test-card__title {
    color: #473F95;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    padding-bottom: 7px;
}
.test-card__time {
    color: #D23168;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 32px;
}
.test-card__language {
    padding-left: 8px;
    color: #303030;
    font-size: 12px;
    line-height: 14px;
}
.test-card__btn {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 16px;
    padding: 14px 24px;
    min-width: 226px;
    margin-bottom: 10px;
    background: #D23168;
    border-radius: 5px;
    border: none;
}
.test-card__remaining-time {
    color: #D23168;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}
.test-card__btn--purple {
    background: #473F95;
}
.test-card__btn--light-purple {
    background: #EEEAFB;
    color: rgba(71, 63, 149, 0.6);
}
.test-card__btn--more {
    min-width: 121px;
    background: #473F95;
    padding: 8px 24px;
    margin-bottom: 0;
}
.test-card__result {
    color: #473F95;
    font-weight: bold;
    font-size: 39px;
    line-height: 46px;
    margin-bottom: 20px;
}
.pagination {
    border: none;
    box-shadow: none;
    margin-bottom: 30px;
    padding-top: 28px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link {
    z-index: 0;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .page-link:focus {
    box-shadow: none;
}
@media (max-width: 991px) {
   .nav-tabs.test-tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
   }
   .test-tabs__title {
       width: 100%;
   }
   .test-tabs .nav-item:first-child {
       margin-right: 12px;
   }
   .test-tabs .nav-item:first-child .test-tabs__title {
       border-radius: 5px;
   }
   .test-tabs .nav-item:last-child .test-tabs__title {
       border-radius: 5px;
   }
   .test-card {
       flex-direction: column;
   }
   .test-card__title {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
   }
   .test-card__time {
       padding-bottom: 7px;
   }
   .test-card__btn {
        display: block;
        margin: 25px auto 10px auto;
   }
   /deep/ .pagination .page-item .page-link {
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        width: 43px;
        height: 32px;
        min-width: 43px;
   }
   .test-card__result {
       font-size: 36px;
        line-height: 42px;
        text-align: center;
        margin-top: 50px;
   }
   .test-card__btn {
       margin-bottom: 0;
   }
   .test-card__remaining-time {
       padding-top: 7px;
   }
   .test-card {
       max-width: 400px;
   }
}
</style>